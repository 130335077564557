


a {
	color: #f6255e;
}

// bootstrap row reset
.row {
	margin-left: 0px;
	margin-right: 0px;
}

nav
{
	z-index: 1001;
}

.navbar-brand {
    float: none;
    height: 50px;
    padding: 0px;
    font-size: 18px;
    line-height: 20px;
    img {
    	max-width: 310px;
    	@media (max-width: 388px) {
    		max-width: 200px;
    	}
    }
}

.modal-body {
  padding: 20px 0 !important;
}

.modal {
  overflow-y: hidden;
}

body.modal-open {
    overflow: auto !important;
    margin-right: 0 !important;  
}
.navbar-nav {
  margin-top: 40px;
}

.navbar-nav>li>a {

	@media (min-width: 768px) {
		padding-top: 45px;
	}
	text-align: center;
    padding-bottom: 15px;
}

.navbar-toggle {
    position: relative;
    float: right;
    padding: 10px 10px;
    margin-top: 30px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
	@media (max-width: 388px) {
		margin-top: 14px;
	}    
}

@media (min-width: 768px) {
	.navbar>.container .navbar-brand, .navbar>.container-fluid .navbar-brand {
	    margin-left: 0px;
	}
}

.modal-content {
	color: #f6255e;
	padding: 15px;
	h2 {
		text-align:center;
	}
}



.banner {
	margin-top: 144px;
	img {
		border-radius: 5px;
	}
}




.panel-top {
	margin-left: 5%;
    top: 175px;
    position: absolute;
    // margin-right: auto;
    height: auto;
    @media (min-width: 991px) {
    	width: 400px;
    }
    width: 100%;
    max-width: 400px;
    border-radius: 5px;
    background-color: #f6255e;
    h1 {
    	text-align: center;
    }
    @media (max-width: 991px) {
    	display:none;
    }    	
}

.panel-top-mobile {
	margin-top: 10px;
	margin-left: auto;
	margin-right: auto;
	background-color: #f6255e;
    width: 100%;
    max-width: 400px;	
    border-radius: 5px;
    h1 {
    	padding: 10px;
    	text-align: center;
    }    
}


#signUpForm label {
    vertical-align: top;
    margin: 0 !important;
    padding: 0 !important;
    height: 30px !important;
    line-height: 30px !important;
    float: none !important;
    text-align: right !important;
    font-family: "Open Sans",Calibri,sans-serif !important;
    font-size: 14px !important;
    display: inline-block !important;
    font-weight: 700 !important;
    margin-bottom: 8px !important;
    width: 22% relative !important;
    top: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
    left: 0 !important;
    color: #F8F8F8 !important;
}

.sec-panel {
	margin-top: 25px;
	background-color: white;
	border-radius: 5px;
	color: #564B3D;
	padding: 15px;
	img {
		margin-left: auto;
		margin-right: auto;
	}
}

.sec-panel-m {
	@media (min-width: 992px) {
		display: none;
	}	
	margin-top: 25px;
	background-color: white;
	border-radius: 5px;
	color: #564B3D;
	padding: 15px;
	img {
		margin-left: auto;
		margin-right: auto;
	}
}

.browse {
	margin-top:25px;
	margin-bottom: 25px;
	text-align: center;
	&-button {
		display: block;
		width: 33%;
		margin-left:auto;
		margin-right: auto;
		border: 1px solid black;
		border-radius: 5px;
		padding: 12px;
		font-size:16px;
		font-weight: 700px;
		&:hover {
			text-decoration: none;
			color: white;
			background-color: #564B3D;
		}	
	}
}

.join {
	margin-top:25px;
	margin-bottom: 25px;
	text-align: center;
	&-button {
		display: block;
		width: 33%;
		margin-left:auto;
		margin-right: auto;
		border: 1px solid black;
		border-radius: 5px;
		padding: 12px;
		font-size:16px;
		font-weight: 700px;
		&:hover {
			text-decoration: none;
			color: white;
			background-color: #f6255e;
		}	
	}
}

.modal-content {
	background-color: #f6255e;
	color: #f8f8f8;
}

.top-pad {
	background-color: #F8F8F8;
	color: #564B3D; 
	margin-top: 27px;
	border-radius: 5px;
	padding:15px;
}

footer {
	margin-top: 10px;
	color: #564B3D; 
	background: white;
	padding-top: 10px;
}

.logo {
	max-width: 250px;
}
i {
	font-size: 70px;
}
h1 {
	color: white;
	position: relative;
	z-index: 1;
	margin: 20px 0;
}

@-webkit-keyframes lightSpeedIn {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }

  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}

@keyframes lightSpeedIn {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }

  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    -ms-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    -ms-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}

.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

@-webkit-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }

  80% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
  }

  80% {
    -webkit-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px);
  }

  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
}

@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg);
  }

  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
  }

  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}

@keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    -ms-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg);
  }

  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    -ms-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
  }

  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}

.flipInX {
  -webkit-backface-visibility: visible !important;
  -ms-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
}